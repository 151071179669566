<template>
    <div style="min-width: 100px">
        <b-badge v-for="(tag,index) in row.item.tags"
                 :key="index"
                 variant="success"
                 class="mr-2"
        >{{ tag }}
        </b-badge>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>